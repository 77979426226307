<template>
  <div>
    <PageBar Image="img/media_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="courses" v-if="Lesson">
        <h2>{{ Lesson.Title }}</h2>
        <div class="course_iframe">
          <iframe id="video_frame"></iframe>
        </div>
      </div>
      <!--
      <div
        class="remainingTime"
        v-if="Lesson && Lesson.VideoType != 2 && Lesson.Price > 0"
      >
        <H2>
          <span v-if="Mode != 'Preview'"
            >若需要暫停觀看可點擊時間暫停，下次繼續時將會從目前暫停時間開始</span
          ><strong>{{ LeftTime | hhmmss }}</strong></H2
        >
        <button v-if="Mode != 'Preview'" @click="pause">時間暫停</button>
      </div>
      -->
      <div class="otherLinks" style="display: none;" v-if="Lesson">
        <div class="examLink" v-if="Lesson.ExerciseUrl">
          <p>習題測驗</p>
          <a :href="Lesson.ExerciseUrl" class="" target="_blank">
            <img src="/img/share.svg" alt=""></a>
          <a :href="Lesson.ExerciseUrl" class="qr-trigger">
            <img src="/img/QRcode.svg" alt="">
            <div text="https://tassm.org.tw/#/exam/88" class="qr-img">
              <img crossorigin="anonymous"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAABKhJREFUeF7t3VFy4zgMBNDkTM79T+A7eb5FV/lVFyjZyfT+bYEEwW6gQVGK5/t2uz2+Lvzvfr8fVvv5+Tn8/2pXaJq/2654pvbvEvI6IVZCp4BrfglBhZaQRdKUUbslSZKqeKb2pwpJNVwBCDDNl33qX/NlV3yyr/5LSChZZydsCSkhx2OvSlr2qaRovuyKT/ZYstJThpqiSl7ryf8KgNZLAUvXVzwlRAwsdlWIEqiELAi0QoZXI8q4VDJKyJCQtcSnBImQVJLShPj1klVCltveszNAGVtCSsjL6/9U0nSIU8LzSV0anmpkWiHaoACbzpf/3fiUkOHVSQkJH9xaIZuPvVNAp/M/TrK0Idm1IZ2q1ATVk7T+1K79yx7fZcmh7NpwCTm+02dTF+Cyl5DXCLVCwh6XJpQSVIrw8V+dCJCr7Sng6fgSEj6HpACn40tICck+Jb1aknTMTjM+Hf/9eDwu/bZXTW16Nza9ykgB3D2+hIRfSu4mYPVXQkrIMSckMdJ02ZXRunrR/N12nrLSDWu8AEibeNpzNF49TvGnBPFJXQHvzugUgOn62l8aT0qA/LdC0ENUsSVkeZBTxqcVpQz+eMlKA0wzToCKkN0Ap/Goglb8xpJVQgT5a3sJ2fxXvzM6vr5KyKcTst5lSeNll2ZL8+V/tz3NcD1naX/C5+nqZLphLaiAp+un80vIgoAy7mx7CSkhBwR4dTLNyKkkKWMlSVPJnM6fPqfwMyABoABSgktI+OcIArgVcvzwTQm22lsh4eVimnAxIXqnLslKNXe6IVXo1fYUcOHFV7glZPYDayLs6ZTVCsl+wCy9TC0hm7/dTRVCBFCy9BN/0mT1BAWQbuDq9VQRKWEaP34fcjVAV69XQsKSSQEL3T+9r1DFp/Fsfx9ydcZevd4U4JRASpYyKu0xAjS9ilF88pfGM92v4i0hC0ICXHYBLnsJKSFHBNKMk6ZLs5Whikd2+Zedl4vpBgWYzuGyK56z11d86lkitISc/GSfHhpKSAnZe5knCUklTv5S+/YKUQDasJrY1XZp+DSe1D+f1OUwbZrTDe6er/1N10v9lxD0jBIyRSCcn2Zw6P7pMjI9BvMVbhpQOl49SvZ0PfU8SXI6v4SEDE0J1/wSUkJCBJbhaYalkqLotP50flwheqeugFK7ABVA6QZTf2r6qV34PH0GVEJmNwcivIScfDfVCgkf5FLAlOFT+7hCpPFaID2n7x6vy7zpervnr/7i6/cSckRAFZTiVUI2/5n0VGFKyG8jROd+abZKOrVrPUlEuh/5S+PRflkh6QbOPuWkAKgJp4BrvCSrhOCqRgCn9hIS/ohlWvH/PSFTAN4NuOJXfB/XQ7Qh9RBtOPWv9VJ/iq+EhIiqR8hdCRFCof3PE6Jj6e5jdLqe+FLGa358l5UumAKYAqRzvOzpegI0xUf+3t5DUoAEuOzpegKwhIT/AIs0PyVQhIpA2VkhciC7Npza02Oo/O8GWJItvErIgtBUgkpI+PNKqYQpo1WxKcGtkE+vkDQj0vFpSU/HK760x+z2t64//rNoBTgt6RKSIhyOnwIsTVaP0KkqnT/11wpBz3g3If8AMN0PLQ/sw70AAAAASUVORK5CYII="
                width="100" height="100" style="width: 100px; height: 100px;">
            </div>
          </a>
        </div>
        <div class="examLink" v-else-if="Exam">
          <p>習題測驗</p>
          <router-link :to="'/exam/' + Lesson.LessonID" class="" target="_blank">
            <img src="/img/share.svg" alt=""></router-link>
          <router-link :to="'/exam/' + Lesson.LessonID" class="qr-trigger">
            <img src="/img/QRcode.svg" alt="">
            <div text="https://tassm.org.tw/#/exam/88" class="qr-img">
              <img crossorigin="anonymous"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAABKhJREFUeF7t3VFy4zgMBNDkTM79T+A7eb5FV/lVFyjZyfT+bYEEwW6gQVGK5/t2uz2+Lvzvfr8fVvv5+Tn8/2pXaJq/2654pvbvEvI6IVZCp4BrfglBhZaQRdKUUbslSZKqeKb2pwpJNVwBCDDNl33qX/NlV3yyr/5LSChZZydsCSkhx2OvSlr2qaRovuyKT/ZYstJThpqiSl7ryf8KgNZLAUvXVzwlRAwsdlWIEqiELAi0QoZXI8q4VDJKyJCQtcSnBImQVJLShPj1klVCltveszNAGVtCSsjL6/9U0nSIU8LzSV0anmpkWiHaoACbzpf/3fiUkOHVSQkJH9xaIZuPvVNAp/M/TrK0Idm1IZ2q1ATVk7T+1K79yx7fZcmh7NpwCTm+02dTF+Cyl5DXCLVCwh6XJpQSVIrw8V+dCJCr7Sng6fgSEj6HpACn40tICck+Jb1aknTMTjM+Hf/9eDwu/bZXTW16Nza9ykgB3D2+hIRfSu4mYPVXQkrIMSckMdJ02ZXRunrR/N12nrLSDWu8AEibeNpzNF49TvGnBPFJXQHvzugUgOn62l8aT0qA/LdC0ENUsSVkeZBTxqcVpQz+eMlKA0wzToCKkN0Ap/Goglb8xpJVQgT5a3sJ2fxXvzM6vr5KyKcTst5lSeNll2ZL8+V/tz3NcD1naX/C5+nqZLphLaiAp+un80vIgoAy7mx7CSkhBwR4dTLNyKkkKWMlSVPJnM6fPqfwMyABoABSgktI+OcIArgVcvzwTQm22lsh4eVimnAxIXqnLslKNXe6IVXo1fYUcOHFV7glZPYDayLs6ZTVCsl+wCy9TC0hm7/dTRVCBFCy9BN/0mT1BAWQbuDq9VQRKWEaP34fcjVAV69XQsKSSQEL3T+9r1DFp/Fsfx9ydcZevd4U4JRASpYyKu0xAjS9ilF88pfGM92v4i0hC0ICXHYBLnsJKSFHBNKMk6ZLs5Whikd2+Zedl4vpBgWYzuGyK56z11d86lkitISc/GSfHhpKSAnZe5knCUklTv5S+/YKUQDasJrY1XZp+DSe1D+f1OUwbZrTDe6er/1N10v9lxD0jBIyRSCcn2Zw6P7pMjI9BvMVbhpQOl49SvZ0PfU8SXI6v4SEDE0J1/wSUkJCBJbhaYalkqLotP50flwheqeugFK7ABVA6QZTf2r6qV34PH0GVEJmNwcivIScfDfVCgkf5FLAlOFT+7hCpPFaID2n7x6vy7zpervnr/7i6/cSckRAFZTiVUI2/5n0VGFKyG8jROd+abZKOrVrPUlEuh/5S+PRflkh6QbOPuWkAKgJp4BrvCSrhOCqRgCn9hIS/ohlWvH/PSFTAN4NuOJXfB/XQ7Qh9RBtOPWv9VJ/iq+EhIiqR8hdCRFCof3PE6Jj6e5jdLqe+FLGa358l5UumAKYAqRzvOzpegI0xUf+3t5DUoAEuOzpegKwhIT/AIs0PyVQhIpA2VkhciC7Npza02Oo/O8GWJItvErIgtBUgkpI+PNKqYQpo1WxKcGtkE+vkDQj0vFpSU/HK760x+z2t64//rNoBTgt6RKSIhyOnwIsTVaP0KkqnT/11wpBz3g3If8AMN0PLQ/sw70AAAAASUVORK5CYII="
                width="100" height="100" style="width: 100px; height: 100px;">
            </div>
          </router-link>
        </div>
        <div class="questLink" v-else-if="Lesson.QuestionnaireUrl">
          <p>課後滿意度調查</p>
          <a href="javascript:void(0)" @click="showQuestion">
            <img src="/img/share.svg" alt="">
          </a>
          <a href="javascript:void(0)" @click="showQuestion" class="qr-trigger">
            <img src="/img/QRcode.svg" alt="">
            <div text="https://forms.gle/FwSeik9yiNGrL9ix9" class="qr-img">
              <img crossorigin="anonymous"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAABMdJREFUeF7tnWF22zAMg9s75f4nyJ2y13lv04tD6YMoJlqC/mxpmQZIEFLc9vt2u92+/LUNAt8mZBsufidiQvbiw4RsxocJMSG7IbBZPuEMuVwuy1O9Xq9/14zWb2PUBFblTPJUc2vje89oQh4ga0IaUNwhwcawbf9VIGXWiSSCSF/mWTLXtjnTdZBkZYCkicxqsgkRkTMhB2AUB7lDiJOJhiL5ftTmYh18RV1dkX+Um/q83aOTiNGKB8qAR8BQSSZArsLhPn93iGB7VTtMiN2SkGjTRHV3JGeZdaqVwoQ0pwUjInvD+K0lyx3yD4EtZgip1Bk9Hq1LpOzjJYu4JgLkiAy6NzAhAZLukOb4vfrohFQ8qVSynyHW9VXkT20MiRSoh2omJDHUTYiKQMFZ1lwK56uIFKjS9Mz4ChymNoYViTwTyFWFUIGDCQnMCimQlxKy6uZkHQKG6o4iY0HyybhKsn4vZos3F00IcFlZppXrTQgghJxmtqBnNmWqRJDc1EFOjmxUGSQ5lA11EzJn800IeHtS7XzSXeQ04medrYe6Mod6sQQMMscI8FEMycGENOiZEACGOyRAgLTbKuejkqDmpjq6TD5kFuGhHlk84qaI1hIgCRhkHRJD7kViosKkhYCGOnkgd8hBVxkhBOCoJQmBmWrLdKA6vDNnaKrKdF2WCTngNCGB+yIdRY4q3qJDyEAiHUUMAQGVkKNquRofydGMs2qvkV+UIztRovFEXzOzSAVYjTchiXdySdVuT0imAkhlE4lTXRwBlcSQ7q1Qiq7LMiHnfUX1LDUhTdWphmObDiGJk6FO7CdxXEQeCXjkXkTKiBT3Om27o5NVRKnW2IQk3o9SwXuLDnmmFBDno8asGsCq0yOy1uveZRtDkgiRIwIAiTEhwRkUkRe1+j+eEDIgCUhkx0w6TZ0JZE1SFOrebEb2kWSZkDMChEATAn4NLzPHXtohaleQashsmjIVWUEC+eBKlWh8dELIMSF9lIjrMyHBH/okx0NP75BMxavJrnJoFbJGHJoaU7YxVAEg0kfIJBVMSCZ7JBVsUsgmJHjj/b8ihFRzpnoqKphsGKMYtTOJc6ODvM0JHb8TckirqhKnVrAJaRAwIQcYBIepGULkiNyctDaRL1VqyOAnMkXUgeRG10GSRUBdBYAKkipr5FkoeKO4shlCHsKEnOkpI2RUCTM/J3IXyaYqERkzkTEKap4/8UiyZgAfXWNCHiNkQv7gUlEgSzuEHJWPuuD+56qmkhyq1yTzk7hEmmfJJ4YzlfHoGhMSbPTUTjAhBwKqDHaH+sxioyonbbvqvqSIiPtSXZa6L7pfH0kWAZIkTtYxIZv9DyoTIhKiDloVYOJqsrIwklYid8RZRTG99WXJMiFnOMn5G5HrqaFuQt6UEOJw1Bi1UjPFpVp7Kt0vkywVbKLZJiQoEzJ0TYh4uEjcDmnbzDpqV7TxqhwRZ0Vkhzxvb8BvJ1kZkk1IgWSZEHFjSFo7qlQyW8i1agzJOSM1mWu3OcvK6LEJEf/wCwHMhCRcFml5EkP2CaqzUuWiwn1Fz06KbkqyCNgkxoSMUUK2d7wMizAhY5xe9tbJOLXPjDAhm/FuQkzIZghslo47xIRshsBm6bhDNiPkF2ZvVFzzWFfnAAAAAElFTkSuQmCC"
                width="100" height="100" style="width: 100px; height: 100px;">
            </div>
          </a>
        </div>
      </div>
    </div>
    <div id="popup" class="popUp hide">
      <div class="alertWindow p_notEnough hide" id="p_notEnough">
        <div class="p_head">
          <p>提示訊息</p>
        </div>
        <div class="p_content">
          <p>
            敬愛的觀看者您好，您目前的點數餘額不足，無法觀看完整的課程影片，僅能預覽30秒，如您欲購買點數，請與我們聯繫：
          </p>
          <p>
            EMAIL: <a href="mailto=tassm2000@gmail.com">tassm2000@gmail.com</a>
          </p>
          <p>LINE@ ID：@tassm</p>
          <p>TEL：(02)2886-1261#22 叢小姐</p>
        </div>
        <div class="p_foot">
          <a class="previewFirst" href="javascript:void(0)" @click="preview">我想先預覽</a><a class="previewFirst"
            href="javascript:history.go(-1)">確定</a>
        </div>
      </div>
      <div class="alertWindow p_notEnough hide" id="play_end">
        <div class="p_head">
          <p>提示訊息</p>
        </div>
        <div class="p_content" v-if="Lesson">
          <p>
            敬愛的觀看者您好，您目前的預覽時間已結束，目前您所剩的點數為{{
              Point | commaFormat
            }}點，該影片需使用{{ Lesson.Price | commaFormat }}點，是否購買影片？
          </p>
          <!-- <p>LINE@ ID：@tassm</p>
          <p>TEL：(02)2886-1261#22 叢小姐</p> -->
        </div>
        <div class="p_foot">
          <a href="javascript:void(0);" @click="endBuy">是</a><a href="javascript:history.go(-1)">否</a>
        </div>
      </div>
      <div class="alertWindow p_enough hide">
        <div class="p_head">
          <p>提示訊息</p>
        </div>
        <div class="p_content">
          <p>敬愛的觀看者您好，</p>
          <ul v-if="Lesson">
            <li>
              您目前的點數剩餘<strong>{{ Point | commaFormat }}</strong>點
            </li>
            <li>
              觀看此課程影片需使用<strong>{{
                Lesson.Price | commaFormat
              }}</strong>點
            </li>
            <li>課程影片長度{{ Lesson.LengthSeconds | chhmmss }}</li>
            <li>開始觀看後請於14天內看完</li>
          </ul>
        </div>
        <div class="p_foot">
          <a class="addToMyCourse" href="javascript:void(0)" :class="{ added: IsFavorite }"><span
              @click="preview">搶先預覽</span><span class="addedShow" v-if="IsFavorite"
              @click="$router.push('/my')">前往觀看紀錄<b>已加入</b></span></a><a v-if="!IsFavorite" @click="addFavorite"
            class="previewFirst" href="javascript:void(0)">加入我的收藏</a><a class="watchDirectly" href="javascript:void(0)"
            @click="buy">立刻購買並觀看</a>
        </div>
      </div>
      <div class="alertWindow firstLearning hide">
        <div class="p_head">
          <p>新手教學</p>
        </div>
        <div class="p_content">
          <div class="flSlide">
            <div class="slide"><img src="img/teach1.jpg" /></div>
            <div class="slide">
              <a href="javascript:void(0)"><img src="img/teach2.jpg" /></a>
            </div>
          </div>
          <p class="knownCheck">
            <label for="known">
              <input type="checkbox" id="known" name="known" value="known" @change="known" />
              我已經了解，下次別再通知我囉！
            </label>
          </p>
        </div>
        <div class="p_foot">
          <a href="javascript:void(0)" @click="closeFirstDialog">開始上課</a>
        </div>
      </div>
      <div class="alertWindow p_expire hide">
        <div class="p_head">
          <p>提示訊息</p>
        </div>
        <div class="p_content" v-if="Lesson">
          <p>
            溫馨提醒，您所購買的{{ Lesson.Title }}課程，將於{{
              ExpireDays
            }}天後到期，請再留意影片觀看期限
          </p>
          <!-- <p>LINE@ ID：@tassm</p>
          <p>TEL：(02)2886-1261#22 叢小姐</p> -->
        </div>
        <div class="p_foot">
          <a href="javascript:void(0);" @click="endExpire">是</a>
        </div>
      </div>
    </div>
    <div id="buyPointDialog2" class="popUp hide buyPointDialog">
      <div class="alertWindow" v-if="Order">
        <div class="p_head">
          <p>購買程序</p>
        </div>
        <div class="p_content">
          <div class="steps">
            <p>申請購買點數</p>
            <p>匯款</p>

            <p>
              以EMAIL回覆匯款帳號後五碼<span>(請寄至:<a href="mailto:tassm2000@gmail.com">tassm2000@gmail.com</a>)</span>
            </p>
            <p>一至兩個工作天後可於信箱收取購買成功EMAIL</p>
          </div>
          <div class="pointChoose">
            欲購買點數
            <select v-model="Order.Point">
              <option :value="200">200</option>
              <option :value="400">400</option>
              <option :value="600">600</option>
              <option :value="800">800</option>
              <option :value="1000">1000</option>
              <option :value="1200">1200</option>
              <option :value="1400">1400</option>
              <option :value="1600">1600</option>
              <option :value="1800">1800</option>
              <option :value="2000">2000</option>
            </select>
            點，總共<span class="total">{{ Order.Point * 1 }}</span>元。
            <div class="paymentInfo">
              匯款資訊如下：<br />
              <h5>(1)彰化銀行 分行：承德分行</h5>
              代碼：009 匯款帳號：50748601289000<br />
              戶名：社團法人臺灣體育運動管理學會<br />
              <h5>(2)郵局</h5>
              代碼：700 匯款帳號：00022310079221<br />
              戶名：社團法人臺灣體育運動管理學會
            </div>
            <div class="vatRow">
              購買發票需要統一編號：<input type="number" class="vat" v-model="Order.VATNumber" />
            </div>
          </div>
          <div class="applyDoc">
            <h3>臺灣體育運動管理學會官方網站<br />數位增能平台點數使用須知</h3>
            <h4>一、適用範圍公告：</h4>
            <p>
              數位學習點數可線上兌換本會透過網頁之體育相關學術研討講演、體育選手訪談、等影片進行觀賞，各影片所需點數請以本會網路公告為主。
            </p>
            <h4>二、購買點數：</h4>
            <ol>
              <li>購買者可自行決定購買點數，每次以200點為單位購買。</li>
              <li>
                如有需一次性購買萬點以上(大額採購)，請洽本會(02-28861261)辦理。
              </li>
              <li>兌換本會學習點數，依購入順序依序扣抵。</li>
            </ol>
            <h4>三、售價：</h4>
            <ol>
              <li>單次購買點數每點1元，以200點為最低購買單位。</li>
              <li>
                一次性購買{{ Config.Point | commaFormat }}點，可優惠{{
                  Config.Price | commaFormat
                }}元。(可隨機調整，活動促銷)
              </li>
            </ol>
            <h4>四、付費方式：</h4>
            <ol>
              <li>
                可選擇使用ATM轉帳付款方式，須一至兩個工作日進行帳務處理，方可完成付款程序。
                匯款資訊如下：<br />
                (1)彰化銀行 分行：承德分行<br />
                代碼：009 匯款帳號：50748601289000<br />
                戶名：社團法人臺灣體育運動管理學會<br />
                (2)郵局 <br />
                代碼：700 匯款帳號：00022310079221<br />
                戶名：社團法人臺灣體育運動管理學會
              </li>
              <li>
                依加值型及非加值型營業稅法第32條規定，點數售出時本會已開立電子發票或收據，後續使用點數觀看影片時，恕不再開立電子發票或收據。
              </li>
              <li>跨行匯款手續費由消費者自行吸收。</li>
            </ol>
            <h4>五、選課方式：</h4>
            <ol>
              <li>
                購買點數並完成付款，且收到本會購買點數成功之通知信函後，即可開始以點數兌換課程。
              </li>
              <li>
                使用點數兌換課程，請於課程開始觀看請於14天內觀賞完畢，期間內無觀看次數限制。
              </li>
            </ol>
            <h4>六、點數退費方式：</h4>
            <ol>
              <li>
                首次購買點數並完成付款，且尚未已點數扣抵任何課程者，若付款後十日內辦理退費，將無息退還全額款項；若超過十日後辦理退費，將以您當初購買之點數金額扣除{{
                  Config.Fee | commaFormat
                }}元行政手續費後，無息退還剩餘款項。
              </li>
              <li>
                購買點數並完成付款後，若曾以點數扣抵課程，且欲辦理退費，將以您目前帳戶內之剩餘點數依當初購買點數之金額換算為現金，並扣除{{
                  Config.Fee | commaFormat
                }}元行政手續費後，根據消費者保護法第19-2條第二項規定，於15天內無息退還剩餘之款項，若款項為零或負數，將不再退款或收費。
              </li>
              <li>
                申請點數退費，皆以當初各次購買之點數價格換算為現金，但本會會員贈送之點數不得申請退費或折換現金。
              </li>
              <li>
                大額採購者，若曾以點數扣抵課程，因故辦理點數退費，致已購買之點數未達大額採購優惠標準，需扣除已使用優惠後，再退還款項。
              </li>
            </ol>
            <h4>七、本平台數位學習點數無使用期限</h4>
            <label for="agreeCheck">
              <input type="checkbox" id="agreeCheck" v-model="Order.ShowSubmit" />
              本人已閱覽並同意以上購買點數之規定
            </label>
          </div>
        </div>

        <div class="p_foot">
          <a href="javascript:void(0)" @click="closeOrderModal" class="cancel_btn">取消</a>
          <a href="javascript:void(0)" :class="{ disable: !Order.ShowSubmit }" @click="submitOrder">申請購買</a>
        </div>
      </div>
    </div>
    <div class="popUp hide" id="question">
      <div class="alertWindow form">
        <div class="p_head">
          <p>課後滿意度調查</p>
        </div>
        <div class="p_content" v-if="Lesson">
          <iframe :src="Lesson.QuestionnaireUrl" width="640" height="1462" frameborder="0" marginheight="0"
            marginwidth="0">載入中…</iframe>
        </div>
        <div class="p_foot"><a href="javascript:void(0)" @click="onQuestionClose">關閉問卷調查</a></div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import moment from "moment";
import user from "../plugins/user";

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Lesson: null,
      Point: 0,
      Mode: "Preview",
      LeftTime: 30,
      IsFavorite: false,
      initialed: false,
      timer: 0,
      fs: null,
      CurrentTime: 0,
      Progress: null,
      Order: null,
      Config: null,
      ExpireDays: 9999,
      Exam: null,
    };
  },
  mounted() {
    this.setBase("media_page");
    this.load();
  },
  methods: {
    async load() {
      if (!user.user) {
        alert("請先登入或註冊！");
        this.$router.replace("/login");
        return;
      }

      if (!localStorage.getItem("firstLearning")) {
        $("#popup").removeClass("hide");
        $(".firstLearning").removeClass("hide");

        setTimeout(() => {
          $(".flSlide").slick({
            arrows: true,
            autoplay: false,
            dots: true,
            //- adaptiveHeight: true
          });
        });
        return;
      }
      this.loadContent();
    },
    async loadContent() {
      var result = await this.$api.getLesson(this.$route.params.id);
      var me = await this.$api.getMe();

      this.Lesson = result.Lesson;
      this.Point = me.Point;
      this.IsFavorite = result.Favorite;
      this.Progress = result.Progress;
      if (!result.DoneExam) {
        this.Exam = result.HasExam;
      }

      var info = result.Info;
      if (info && info.ExpireTime) {
        this.ExpireDays = moment(info.ExpireTime).diff(moment(), "day");
      }
      var showPopup = () => {
        $("#popup").removeClass("hide");
        if (this.Lesson.Price <= this.Point) {
          $(".p_enough").removeClass("hide");
        } else {
          $("#play_end").removeClass("hide");
        }
      };
      if (this.Lesson.Price > 0) {
        if (info) {
          if (moment().isAfter(info.ExpireTime) || info.LeftSeconds <= 0) {
            showPopup();
          } else {
            this.LeftTime = info.LeftSeconds;
            this.buyed();
          }
        } else {
          showPopup();
        }
      } else {
        await this.$api.visitLesson(this.$route.params.id);
      }

      this.Breadcrumbs = [
        { Title: "數位增能平台", Url: "/lessons" },
        { Title: "講座數位影音資料庫", Url: "#" },
      ];

      this.loadCustomJs();

      this.initVideo(() => { });
    },
    initVideo(callback) {
      if (this.initialed) {
        return;
      }
      this.initialed = true;
      //$("#popup").addClass("hide");
      if (this.Lesson.VideoType == 0) {
        this.$nextTick(() => {
          var url = `upload/courses/${this.Lesson.LessonKey}/index.html`;
          //console.log(url);
          $("#video_frame").load(() => {
            console.log("video loaded");
            setTimeout(() => {
              var iframe = document.getElementById("video_frame");
              var iwindow = iframe.contentWindow
                ? iframe.contentWindow
                : iframe.contentDocument.defaultView;
              var fs = iwindow.fs;
              if (!fs || !fs.event) {
                console.log("!fs");
              }
              var video = $('video', $(iframe).contents());
              if (video) {
                video.on('ended', event => {
                  this.done();
                })
              }
              console.log(fs);
              fs.event.register("player.play", () => {
                console.log("player.play");
                this.start();
                if (this.timer == 0 && this.Lesson.Price > 0) {
                  this.timer = setInterval(() => {
                    console.log(this.LeftTime);
                    this.LeftTime--;
                    //this.CurrentTime = this.LeftTime;
                    //console.log('xx=' + (this.Lesson.LengthSeconds - this.LeftTime))
                    this.countdown();
                    if (this.LeftTime <= 0) {
                      this.LeftTime == 0;
                      clearInterval(this.timer);
                      fs.video.pause();
                    }
                  }, 1000);
                  console.log(this.timer);
                }
              });
              fs.event.register("player.pause", () => {
                clearInterval(this.timer + ' pause');
                this.timer = 0;
              });
              fs.event.register("player.stop", () => {
                clearInterval(this.timer + ' stop');
                this.timer = 0;
              });
              this.fs = fs;
              callback();
            }, 500);
          });
          $("#video_frame").attr("src", url);
        });
      } else if (this.Lesson.VideoType == 1) {
        this.$nextTick(() => {
          var url = `/player.html?id=${this.Lesson.LessonID}`;
          $(".course_iframe").addClass("mp4Type");
          $("#video_frame").load(() => {
            console.log("video loaded");
            setTimeout(() => {
              var iframe = document.getElementById("video_frame");
              var iwindow = iframe.contentWindow
                ? iframe.contentWindow
                : iframe.contentDocument.defaultView;
              var fs = iwindow.document.getElementById("video");
              fs = $(fs);
              if (!fs) {
                console.log("!fs");
              }
              if (this.Progress && this.Progress.Time) {
                fs[0].currentTime = this.Progress.Time;
              }
              var video = $('video', $(iframe).contents());
              if (video) {
                video.on('ended', event => {
                  this.done();
                })
              }
              fs.on("play", () => {
                console.log("player.play");
                this.start();
                if (!this.timer && this.Lesson.Price > 0) {
                  this.timer = setInterval(() => {
                    console.log(this.LeftTime);
                    this.LeftTime--;
                    this.countdown();
                    if (this.LeftTime <= 500) {
                      this.LeftTime == 0;
                      clearInterval(this.timer);
                      try {
                        fs[0].pause();
                      } catch (e) {
                        console.error(e);
                      }
                    }
                  }, 1000);
                }
              });
              fs.on("pause", () => {
                clearInterval(this.timer);
                this.timer = null;
              });
              fs.on(
                "timeupdate",
                (e) => (this.CurrentTime = fs[0].currentTime)
              );
              this.fs = fs;
              callback();
            }, 500);
          });
          $("#video_frame").attr("src", url);
        });
      } else if (this.Lesson.VideoType == 2) {
        this.$nextTick(() => {
          $(".course_iframe").html(this.Lesson.VideoUrl);
          this.done();
        });
      }
      console.log("loaded");
    },
    done() {
      console.log('video ended');
      $('.otherLinks').css('display', 'block');
    },
    async start() {
      this.$api.setBlockUI(false);
      await this.$api.startLesson(this.$route.params.id);
      if (this.Lesson.Price == 0) {
        this.Mode = "Normal";
      }
      if (this.Mode == "Preview") {
        await this.$api.startPreview(this.$route.params.id);
      }
      this.$api.setBlockUI(true);
    },
    showEnd() {
      if (this.Lesson.Price == 0) {
        return;
      }
      $(".p_enough").addClass("hide");
      //$("#p_notEnough").removeClass("hide");
      $("#popup").removeClass("hide");
      $("#play_end").removeClass("hide");
      $(".p_expire").addClass("hide");
    },
    async countdown() {
      if (this.Lesson.Price == 0) {
        return;
      }
      if (this.Mode == "Preview") {
        if (this.LeftTime <= 0) {
          this.showEnd();
        }
        return;
      }
      try {
        try {
          this.$api.setBlockUI(false);
          var result = await this.$api.countdownLesson(
            this.$route.params.id,
            this.CurrentTime
          );
          this.$api.setBlockUI(true);
        } catch (e2) { }

        if (result <= 0) {
          clearInterval(this.timer);
          this.timer = 0;
          this.fs.video.pause();
          //window.history.go(-1);showEnd();
        }
      } catch (e) {
        if (e.Status == 3) {
          showEnd();
        }
      }
    },
    pause() {
      clearInterval(this.timer);
      this.timer = 0;
      this.fs.video.pause();
    },
    async preview() {
      this.$api.setBlockUI(true);
      this.Mode = "Preview";
      if (!(await this.$api.getPreview(this.$route.params.id))) {
        this.showEnd();
        return;
      }
      $("#popup").addClass("hide");
      /*this.initVideo(() => {
        $("#popup").addClass("hide");
      });*/
    },
    buyed() {
      this.Mode = "Normal";
      this.initVideo(() => {
        console.log(this.ExpireDays);
        if (this.ExpireDays <= 7) {
          $("#popup").removeClass("hide");
          $(".p_expire").removeClass("hide");
        } else {
          $("#popup").addClass("hide");
          $(".p_expire").addClass("hide");
        }
      });
    },
    async buy() {
      try {
        var result = await this.$api.buyLesson(this.$route.params.id);
        this.LeftTime = result.LeftSeconds;
        //this.buyed();
        this.Mode = "Normal";
        $("#popup").addClass("hide");
      } catch (e) {
        if (e.Status == 8) {
          alert("點數不足");
        }
      }
    },
    async addFavorite() {
      await this.$api.addFavoriteLesson(this.$route.params.id);
      this.IsFavorite = true;
      this.$router.push("/my?p=favorite");
    },
    closeFirstDialog() {
      $("#popup").addClass("hide");
      $(".firstLearning").addClass("hide");
      this.loadContent();
    },
    known() {
      localStorage.setItem("firstLearning", $("#known").prop("checked"));
    },
    async endBuy() {
      var me = await this.$api.getMe();
      if (me.Point >= this.Lesson.Price) {
        this.buy();
      } else {
        this.showBuy();
      }
    },
    async showBuy() {
      var config = await this.$api.getConfig("MasBuyPoints");
      if (!config) {
        this.Config = {
          Point: 0,
          Price: 0,
          Fee: 0,
        };
      } else {
        this.Config = JSON.parse(config);
      }
      this.Order = {
        Point: 200,
        VATNumber: "",
        ShowSubmit: false,
      };
      $("#play_end").addClass("hide");
      $("#buyPointDialog2").removeClass("hide");
      $("#popup").addClass("hide");
      this.$nextTick(() => {
        $(".applyDoc").scrollTop(0);
      });
    },
    async submitOrder() {
      await this.$api.submitOrder(this.Order);
      alert("已收到您的申請");
      $("#buyPointDialog2").addClass("hide");
      $(".popUp").addClass("hide");
      window.history.go(-1);
    },
    closeOrderModal() {
      $("#buyPointDialog2").addClass("hide");
      $(".popUp").addClass("hide");
    },
    endExpire() {
      $("#popup").addClass("hide");
      $(".p_expire").addClass("hide");
    },
    showQuestion() {
      $("#question").removeClass("hide");
    },
    onQuestionClose() {
      if (confirm('請確認問卷已填答完成後再關閉此視窗')) {
        $("#question").addClass("hide");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
  },
  beforeRouteLeave(to, from, next) {
    console.log("leave lesson");
    clearInterval(this.timer);
    this.timer = 0;
    next();
  },
};

//fs.video.pause()
//fs.video.play()
</script>